$color-bg: #000000;
$color-bg-secondary: #14365A;
$color-bg-overlay: black;
$color-text-primary: #FFFFFF;
$color-text-secondary: black;
$color-text-tertiary: #FFFFFF;
$color-primary: #FFFFFF;
$color-secondary: #FF82D2;
$color-tertiary: #33A8FF;
$color-disabled: #4d4d4d;
